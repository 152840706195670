/*
 * Couleurs
 * ----------------------------------------------------------------------------*/
$firstColor: #0098a8; // Couleur principale du site : titre principal, fonds...
$secondColor: #ffdd0d; // Couleur secondaire du site : titres, menus...
$menuColor: #0098a8; // Couleur de fond du menu
$menuTextColor: #FFF; // Couleur de texte du menu
$bgColor: #efefef; // Couleur de fond sur le site
$textColor: #6d6d6d; // Couleur par défaut des textes
$borderColor: #e5e5e5;
$defaultTask: #6d6d6d;
$locationColor: #CC44AA;
$ownerColor: #2220a5;
$taskAccueil: #22D72B;
$taskDepart: #CD401C;
$taskRotation: #FFFF00;

$valid: #68bb4c;
$error: #FF6666;
