/*
	STRUCTURE GLOBALE - Feuille de styles
	Author: Nico Blandel | @NicoBlandel
	Author URI: www.nicoblandel.fr
*/

/*
 * Global Styles
 * ----------------------------------------------------------------------------*/
#container {
	padding-top: $header_height_mobile;

	// &.offline {
	// 	display: flex;
	// 	justify-content: center;
	// 	align-items: center;
	// 	padding: 20px;
	// 	text-align: center;
	// 	background: $firstColor;
	// 	color: #FFF;
	// }
	@media (min-width: $tablet) {
		padding-top: $header_height;
	}
}

main {
	overflow: hidden;
}


// MAIN CONTAINER - LOADER
.container {
	position: relative;
	min-height: 100vh;

  .loader {
  	position: absolute;
  	top: 0;
  	left: 0;
  	width: 100%;
  	height: 100%;
  	background: #ececec;
  	text-align: center;
  	opacity: 0;
  	visibility: hidden;
  	transition: opacity .4s ease-in-out,
  							visibility 0s .4s linear;
    img {
    	display: block;
    	margin: 50px auto 0;
    }
  }
  &.loadin {
    .loader {
    	opacity: 1;
    	visibility: visible;
    	transition: opacity .4s ease-in-out,
    							visibility .4s 0s linear;
    }
  }
}
