/*
	MAIN & RESET - Feuille de styles
	Author: Nico Blandel | @NicoBlandel
	Author URI: www.nicoblandel.fr
*/

/** Global defaut
  * --------------------------------------------- */
* {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;
	-webkit-font-smoothing:antialiased;
	font-smoothing:antialiased;
}

*:after,
*:before {
	text-rendering:optimizeLegibility;
}

html {
	font-family: $font-base;
	font-size: $typosize;
  margin: 0;
  padding: 0;
	line-height: 1.2;

	-ms-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  padding: 0;
	color: $textColor;
  background: #FFF;
	hyphens: none;
	position: relative;
	right: 0;
}


/** Contenus
  * --------------------------------------------- */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
abbr[title] {
  border-bottom: 1px dotted;
}
b, strong {
  font-weight: 700;
}
em, i, dfn {
  font-style: italic;
}
img {
	display: block;
	border: none;
	max-width:100%;
  height: auto;

	pointer-events: none;
	user-drag: none;
	user-select: none;
}
ul {
	margin: 0;
	padding: 0;
	list-style: none;
}
p {
	margin: 0;
	padding: 0;
}

/** Liens
  * --------------------------------------------- */
a {
	text-decoration:none;
	color: $firstColor;

	&:hover, &:focus, &:active {
		outline: 0;
		text-decoration: underline;
	}
}
button {
	border: 0;
  padding: 0;
	font-size: 1rem;
}


/** Misc
  * --------------------------------------------- */
::selection {
	background: $firstColor;
	color: #FFF;
	text-shadow: none;
}
::-webkit-selection {
	background: $firstColor;
	color: #FFF;
	text-shadow: none;
}
::-moz-selection {
	background: $firstColor;
	color: #FFF;
	text-shadow: none;
}

meta, .fullGrid > span[itemprop="author publisher"], .tableGrid > span[itemprop="author publisher"] {
	width: 0 !important;
	height: 0 !important;
	overflow: hidden;
}

/*------------------------------------*\
    RESPONSIVE
\*------------------------------------*/
@-ms-viewport {
	width: device-width;
}
@viewport {
	width: device-width;
}
