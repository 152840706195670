/*
 * Fonts
 * ----------------------------------------------------------------------------*/
$font-base: 'Helvetica', sans-serif;

/*
 * Variables / tailles
 * ----------------------------------------------------------------------------*/
$typosize: 16px;
$header_height: 60px;
$header_height_mobile: 60px;
